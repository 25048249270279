<template lang="en">
<div>
    <h1>Imprint/Legal</h1>
    <h2>Information according to § 5 TMG:</h2>
    <p>Marcus Harting<br>Technical Artist<br>Marcus Universe</p>

    <h3>Kontakt:</h3>
    <p>E-Mail: <a href="mailto:contact@marcus-universe.de">contact@marcus-universe.de</a> (preferred)</p>

    <h3>Address:</h3>

    <p><b>Factory Hammerbrooklyn</b></p>
    <p>Stadtdeich 2-4<br>20097 Hamburg<br></p>
    <br>
    <p><b class="red">Please do not send letters to the Factory!  <br>Please contact me by E-Mail!</b></p>

    <br>
    <p>
    It is only the Telemedia Act (TMG) that obligates all website operators to disclose their private addresses. Myself and many other creatives and private individuals who do not wish to be exposed to potential stalkers are facing a significant issue with this regulation. 
    <br>
    <br>
    Please support this petition to have this law repealed: <a href="https://epetitionen.bundestag.de/petitionen/_2023/_06/_12/Petition_152089.nc.html">Petition</a>
    </p>

    <p><br></p>
    <p></p>
    <h2>Data protection / website information</h2>

    <h3>Hoster</h3>
    <p>This website runs on  <a href="https://www.strato.de/" target="_blank" rel="noopener noreferrer">Strato</a>  servers and therefore the routes are hashed, <br>as no server configurations can be made to Strato Hosting. </p>

    <h3>Google Fonts</h3>
    <p>All fonst/fonts are stored locally on the server and are not downloaded from Google servers.</p>

    <h3>Contact form</h3>
    <p>For the contact form <a href="https://formsubmit.co/" target="_blank" rel="noopener noreferrer">Form Submit</a> has been used. The data which ends up in the form will not be stored and will only be forwarded to me. The data will also not be passed on to third parties.
    </p>

    <br>

    <p><strong>Disclaimer:  </strong><br><br><strong>Legal liability for contents</strong><br><br>
        The contents of our pages were created with the utmost care. However, we cannot guarantee the accuracy, completeness and timeliness of the content. As a service provider, we are responsible for our own content on these pages under the general laws according to § 7 para.1 TMG. According to §§ 8 to 10 TMG, however, we are not obligated as a service provider to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information under the general laws remain unaffected. However, liability in this regard is only possible from the point in time at which a concrete infringement of the law becomes known. If we become aware of any such infringements, we will remove this content immediately.<br><br><strong>Responsibility for links</strong><br><br>
        Our offer contains links to external websites of third parties, on whose contents we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognizable at the time of linking. However, a permanent control of the contents of the linked pages is not reasonable without concrete evidence of a violation of the law. If we become aware of any infringements, we will remove such links immediately.<br><br><strong>Copyright</strong><br><br>
        The content and works created by the site operators on these pages are subject to German copyright law. The reproduction, editing, distribution and any kind of exploitation outside the limits of copyright require the written consent of the respective author or creator. Downloads and copies of this site are only permitted for private, non-commercial use. Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is identified as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove such content immediately.<br><br><strong>Privacy</strong><br><br>
        The use of our website is generally possible without providing personal data. Insofar as personal data (for example, name, address or e-mail addresses) are collected on our pages, this is always done, as far as possible, on a voluntary basis. This data will not be passed on to third parties without your express consent. <br>
        We point out that data transmission over the Internet (eg communication by e-mail) security gaps. A complete protection of the data against access by third parties is not possible. <br>
        The use of contact data published within the framework of the imprint obligation by third parties for the purpose of sending unsolicited advertising and information materials is hereby expressly prohibited. The operators of the pages expressly reserve the right to take legal action in the event of the unsolicited sending of advertising information, such as spam e-mails.<br>
        <br><br><strong>Google Analytics</strong><br><br>
        This website uses Google Analytics, a web analytics service provided by Google, Inc (''Google''). Google Analytics uses so-called ''cookies'', which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>
    <br>
    <p class="ImpressumGenerator">
        Website imprint are created with
        <a href="https://www.impressum-generator.de">impressum-generator.de</a>
        from
        <a href="https://www.kanzlei-hasselbach.de/" rel="nofollow">Kanzlei Hasselbach</a>
    </p>
</div>
</template>

<script>
export default {};
</script>

<style lang=""></style>
